<template>
  <div class="w-full lg:w-8/12 mx-auto">
    <h1 class="font-bold mt-4 text-2xl mb-6">QA Tools (Offline Manager)</h1>
    <div class="box-style grid grid-cols-1 lg:grid-cols-2">
      <div class="col-span-1">
        <!-- Setting per module -->
        <div v-for="(module, index) in moduleList" :key="index" class="flex p-5 gap-x-3 items-center border-b border-gray-400"
        >
          <div class="flex-1 leading-tight">
            <h2 class="text-lg font-semibold m-0 capitalize">{{ module.name }}</h2>
            <span v-if="module.totalRecords > 0" class="border border-gray-800 text-black rounded-md px-2 py-1 mt-2 inline-block font-light text-xs"> {{module.totalRecords}} Records Offline</span>
            <span v-else class="bg-yellow-200 border border-yellow-600 text-black rounded-md px-2 py-1 mt-2 inline-block font-light text-xs"> Empty </span>
          </div>
          
          <button @click="module.showConfirmDelete = true" v-if="!module.showConfirmDelete" class="flex-initial border border-red-600 text-red-600 p-2 text-sm rounded-lg">
            Clear Data
          </button>

          <button @click="clearTable(module.name)" v-if="module.showConfirmDelete" class="flex-initial bg-red-600 text-white p-2 text-sm rounded-lg">
            Delete ?
          </button>
          <button @click="module.showConfirmDelete = false" v-if="module.showConfirmDelete" class="flex-initial border border-gray-600 text-gray-600 p-2 text-sm rounded-lg">
            Cancel
          </button>
        </div>
        <!-- End Setting per module -->
      </div>

      <!-- Sync History -->
      <div class="col-span-1 border-l border-gray-300">
        <h2 class="history-title px-5 py-4 text-lg font-extrabold m-0">
          Other Options
        </h2>
        <!-- Clear Cache -->
        <div v-if="appCacheCount > 0" class="flex p-5 gap-x-3 items-center border-b border-gray-400"
        >
          <div class="flex-1 leading-tight">
            <h2 class="text-lg font-semibold m-0 capitalize">Clear Cache</h2>
            <span v-if="!appCache.cleared" class="border border-gray-800 text-black rounded-md px-2 py-1 mt-2 inline-block font-light text-xs">Clears all offline app assets for this website</span>
            <span v-else class="bg-green-700 text-white rounded-md px-2 py-1 mt-2 inline-block font-light text-xs">Cache has been cleared!</span>
          </div>
          
          <button @click="appCache.showConfirmDelete = true" v-if="!appCache.showConfirmDelete" class="flex-initial border border-red-600 text-red-600 p-2 text-sm rounded-lg">
            Clear Cache
          </button>

          <button @click="clearCache()" v-if="appCache.showConfirmDelete" class="flex-initial bg-red-600 text-white p-2 text-sm rounded-lg">
            Delete ?
          </button>
          <button @click="appCache.showConfirmDelete = false" v-if="appCache.showConfirmDelete" class="flex-initial border border-gray-600 text-gray-600 p-2 text-sm rounded-lg">
            Cancel
          </button>
        </div>
        <!-- End Clear Cache -->

        <!-- Restore Cache -->
        <div v-if="appCacheCount < 1" class="flex p-5 gap-x-3 items-center border-b border-gray-400"
        >
          <div class="flex-1 leading-tight">
            <h2 class="text-lg font-semibold m-0 capitalize">Restore Cache</h2>
            <span v-if="!appCache.restored" class="border border-gray-800 text-black rounded-md px-2 py-1 mt-2 inline-block font-light text-xs">Caches this website for offline viewing</span>
            <span v-else class="bg-green-700 text-white rounded-md px-2 py-1 mt-2 inline-block font-light text-xs">Website has been cached!</span>
          </div>
          
          <button @click="appCache.showConfirmRestore = true" v-if="!appCache.showConfirmRestore" class="flex-initial border border-blue-600 text-blue-600 p-2 text-sm rounded-lg">
            Cache Website
          </button>

          <button @click="cacheWebsite()" v-if="appCache.showConfirmRestore" class="flex-initial bg-blue-600 text-white p-2 text-sm rounded-lg">
            Restore ?
          </button>
          <button @click="appCache.showConfirmRestore = false" v-if="appCache.showConfirmRestore" class="flex-initial border border-gray-600 text-gray-600 p-2 text-sm rounded-lg">
            Cancel
          </button>
        </div>
        <!-- End Restore Cache -->
      </div>
      <!-- End Sync History -->
    </div>
  </div>
</template>

<script>
import AdvancedSearch from "../../offline-module/advancedSearch";
import { ModuleConfig } from "../../offline-module/config";
import { eventBus } from '../../../src/main.js'
import { GET_USER_BUSINESS_ID } from '../../browser-db-config/localStorage';
import FallBackTracker from '../../offline-module/fallBackTracker';
export default {
  data() {
    return {
      moduleList : [],
      adv: new AdvancedSearch({
        BusinessId: GET_USER_BUSINESS_ID(),
        offlineManager: this.$GlobalOfflineManager,
      }),
      appCache: {
        showConfirmDelete: false,
        cleared: false,
        showConfirmRestore: false,
        restored: false
      },
      appCacheCount: 0
    };
  },
  async created() {
    await this.getRecordCount()
    const cacheName = 'traction-install-time-v1'
    await this.checkOfflineFilesExists(cacheName)
  },
  watch: {
      SWData(newValue, oldValue) {
      const downloadOnlyDone = this.isADownloadOnlySyncEvent(newValue, 'payments')
      const dualSyncComplete = this.isACompletedDualSyncEvent(newValue, 'payments')
      
      if (downloadOnlyDone || dualSyncComplete) {
        console.log('either download or dualSync event has completed in QA')
        this.getRecordCount()
      }
    }
  },
  methods: {
      async checkOfflineFilesExists(cacheName) {
        let fileCount = 0
        const cache = await (await caches.keys()).find(cacheObject => cacheObject === cacheName)
        console.log('cache check: ', cache)
        
        if (cache) {
          fileCount = await (await (await caches.open(cache)).keys()).length
          // debugger
        } else {
          fileCount = 0
        }

        this.appCacheCount = fileCount
      },
      clearCache() {
        const cacheName = 'traction-install-time-v1'
        return caches.delete(cacheName).then(() => {
          this.appCache.cleared = true
          this.appCache.showConfirmDelete = false
          this.checkOfflineFilesExists()
        })
      },
      async getRecordCount() {
          const modules = Object.keys(ModuleConfig)
          const totalCounts = await Promise.all(modules.map(module => this.adv.getTotalRecords(module)))
          const generated = modules.map((module, index) => {
              return {
                  name : module,
                  totalRecords: totalCounts[index],
                  showConfirmDelete: false
              }
          })
          this.moduleList = generated
      },
      cacheWebsite() {
        this.clearCache().then(() => {
          return this.getFilesForCaching()
        }).then(() => {
          console.log('caching complete!')
          this.appCache.restored = true
          this.appCache.showConfirmRestore = false
          window.location.reload()
        })
      },
      async clearTable(tableName) {
          await this.$GlobalOfflineManager.clearTable(tableName)
          const fallback = new FallBackTracker({BusinessID: GET_USER_BUSINESS_ID()})
          fallback.clearMetaData(tableName)
          this.getRecordCount()
      }
  },
};
</script>
<style scoped>
.history-title {
  background: #effaff;
}
.box-style {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(22, 120, 176, 0.12);
  border-radius: 15px;
}
</style>